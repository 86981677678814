// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-gatsby-theme-tsv-zorneding-src-layout-blog-list-template-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/layout/blog-list-template.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-layout-blog-list-template-js" */),
  "component---plugins-gatsby-theme-tsv-zorneding-src-layout-blog-post-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/layout/blog-post.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-layout-blog-post-js" */),
  "component---plugins-gatsby-theme-tsv-zorneding-src-layout-static-page-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/layout/static-page.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-layout-static-page-js" */),
  "component---plugins-gatsby-theme-tsv-zorneding-src-layout-tag-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/layout/tag.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-layout-tag-js" */),
  "component---plugins-gatsby-theme-tsv-zorneding-src-pages-404-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/pages/404.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-pages-404-js" */),
  "component---plugins-gatsby-theme-tsv-zorneding-src-pages-tags-index-js": () => import("./../../../plugins/gatsby-theme-tsv-zorneding/src/pages/tags/index.js" /* webpackChunkName: "component---plugins-gatsby-theme-tsv-zorneding-src-pages-tags-index-js" */)
}

